import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import RichText from "../components/rich-text"
import GelatinConverter from "../components/gelatin-converter"
import IngredientListScaler from "../components/ingredient-list-scaler"
import ScalerToIngredient from "../components/scaler-to-ingredient"

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: theme.spacing(4),
  },
  header: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const GeneralTemplate = ({ data }) => {
  const classes = useStyles()
  const page = data.contentfulPage

  const RecipeResizerGooglePlayDownloadButton = () => {
    return (
      <a href="https://play.google.com/store/apps/details?id=com.dessertisans.recipe_resizer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          style={{ maxWidth: "240px", marginBottom: "0" }}
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    )
  }

  const customComponents = {
    GelatinConverter: GelatinConverter,
    IngredientsListScaler: IngredientListScaler,
    ScalerToIngredient: ScalerToIngredient,
    RecipeResizerGooglePlayDownloadButton:
      RecipeResizerGooglePlayDownloadButton,
  }
  return (
    <Layout location={data.location}>
      <Seo title={page.title} />
      <div style={{ background: "#fff" }}>
        <Container maxWidth="md" className={classes.header}>
          <Typography variant="h1" component="h1" gutterBottom align="center">
            {page.title}
          </Typography>
        </Container>

        {page.heroImage && (
          <Container maxWidth="md">
            <GatsbyImage
              image={page.heroImage.gatsbyImageData}
              alt={page.title}
            />
          </Container>
        )}

        <Container maxWidth="md" className={classes.main}>
          {(page.contentBlocks || []).map((contentBlock) => {
            switch (contentBlock.__typename) {
              case "ContentfulCbRichText":
                return (
                  <Box key={contentBlock.id} mb={6}>
                    <RichText richTextJson={contentBlock.text.raw} />
                  </Box>
                )
              case "ContentfulCbCustom":
                const CustomComponent =
                  customComponents[contentBlock.componentId]
                return (
                  <Box key={contentBlock.id} mb={6}>
                    {contentBlock.showTitle && contentBlock.title && (
                      <Typography
                        variant="h2"
                        gutterBottom
                        id="gelatin-conversion-calculator"
                      >
                        {contentBlock.title}
                      </Typography>
                    )}
                    <CustomComponent contentBlock={contentBlock} />
                  </Box>
                )
              default:
                return null
            }
          })}
        </Container>
      </div>
    </Layout>
  )
}

export default GeneralTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(path: { eq: $slug }) {
      title
      path
      contentBlocks {
        __typename
        ... on ContentfulCbRichText {
          id
          text {
            raw
          }
        }
        ... on ContentfulCbCustom {
          id
          title
          showTitle
          componentId
        }
      }
      heroImage {
        gatsbyImageData
      }
    }
  }
`
